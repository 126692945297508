import { styled } from '@mui/material';
import { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import mainImage from '../assets/main.jpg'
import ScrollFadeComponent from './ScrollFadeComponents';

const Container = styled('div')({
    backgroundImage: `url(${mainImage})`,
    backgroundPosition: '-100px',
    backgroundSize: 'cover',
    height: 570,
    display: 'flex',
    justifyContent: 'flex-end',
    borderRadius: 16,
    overflow: 'hidden',
})

const LittleContainer = styled("div")({
    overflow: 'hidden',
    width: 'calc(100%)',
    borderRadius: 12,
})

const LittleImage = styled("img")({
    objectFit: 'cover',
    objectPosition: 'left bottom',
    width: '100%',
    marginTop: -100,
    minHeight: 400,
    height: 400,
})

const TextContainer = styled("div")({
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    width: '100%',
    padding: 18,
    boxSizing: 'border-box',
    fontSize: 17
})

interface MainPhotoProps {

}

const MainPhoto: FC<MainPhotoProps> = (props) => {

    const [variant, setVariant] = useState(0)


    const handleResize = useCallback(() => {
        const screenX = window.innerWidth
        if (screenX < 710) return setVariant(1)
        return setVariant(0)
    }, [])


    useEffect(() => {
        handleResize()
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [handleResize])


    const renderContent = () => {
        return (
            <>
                Jestem młodym deweloperem specjalizującym się w Nodejs. Na
                codzień tworzę wraz z bratem aplikację mobilną Greenfield, w
                której odpowiadam za rozwój backendu. Na ten moment mamy
                ponad 2 tys użytkowników.
                <br />
                <br />
                Po godzinach pracuję dla firmy programistycznej HXS z Krakowa przy tworzeniu
                aplikacji to zarządzania produkcją części do dronów.
                <br />
                <br />
                Lubię brać udział w spotkaniach i dogadywać szczegóły projektów.
                Bardzo sobię cenie to co robię i chcę się dalej rozwijać w tym
                kierunku.
                <br />
                <br />
                Na co dzień pomagam w rodzinnym gospodarstwie, uczęszczam na siłownię, rozwijam własne projekty i dokształcam się z różnych aspektów produkcji oprogramowania.
                <br />
                <br />
                Ciągła chęć rozwoju w dziedzinie programowania, sprawia, że nie nudzę się tym co robię oraz widzę w tym swoją przyszłość.
            </>
        )
    }


    const render = (): ReactNode => {
        if (variant === 1) return (
            <LittleContainer>
                <ScrollFadeComponent>
                    <LittleImage
                        src={mainImage}
                    />
                </ScrollFadeComponent>

                <ScrollFadeComponent>
                    <TextContainer
                        style={{
                            width: '100%'
                        }}
                    >
                        {renderContent()}
                    </TextContainer>
                </ScrollFadeComponent>
            </LittleContainer>
        )

        return (
            <Container>
                <TextContainer
                    style={{
                        width: 360,
                    }}
                >
                    {renderContent()}
                </TextContainer>
            </Container>
        )
    }


    return (
        <>
            {render()}
        </>
    )
}

export default MainPhoto;