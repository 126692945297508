import { FC, useEffect, useMemo, useRef, useState } from 'react';
import Name from '../components/Name';
import Role from '../components/Role';
import { Link, Stack } from '@mui/material';
import ProjectSwiper from '../components/ProjectSwiper';
import greenfield01 from '../assets/greenfield/01.png'
import greenfield02 from '../assets/greenfield/02.png'
import greenfield03 from '../assets/greenfield/03.png'
import greenfield04 from '../assets/greenfield/04.png'
import greenfield05 from '../assets/greenfield/05.png'
import greenfield06 from '../assets/greenfield/06.png'
import greenfield07 from '../assets/greenfield/07.png'
import greenfield08 from '../assets/greenfield/08.png'
import greenfield09 from '../assets/greenfield/09.png'
import ptracker01 from '../assets/ptracker/01.png'
import ptracker02 from '../assets/ptracker/02.png'
import ptracker03 from '../assets/ptracker/03.png'
import ptracker04 from '../assets/ptracker/04.png'
import ptracker05 from '../assets/ptracker/05.png'
import ptracker06 from '../assets/ptracker/06.jpeg'
import ptracker07 from '../assets/ptracker/07.jpeg'
import tasko1 from '../assets/tasko/01.png'
import tasko2 from '../assets/tasko/02.png'
import tasko3 from '../assets/tasko/03.png'
import tasko4 from '../assets/tasko/04.png'
import tasko5 from '../assets/tasko/05.png'
import ProjectName from '../components/ProjectName';
import Line from '../components/Line';
import Description from '../components/Description';
import { appConfig } from '../config';
import TechnologyContainer from '../components/TechnologyContainer';
import TechnologyItem from '../components/TechnologyItem';
import jsLogo from '../assets/logos/js.png'
import tsLogo from '../assets/logos/ts.png'
import mongodbLogo from '../assets/logos/mongodb.png'
import gitLogo from '../assets/logos/git.png'
import gitlabLogo from '../assets/logos/gitlab.png'
import nodeLogo from '../assets/logos/node.png'
import nestLogo from '../assets/logos/nest.png'
import ionicLogo from '../assets/logos/IonicLogo.png'
import firebaseLogo from '../assets/logos/FirebaseLogo.png'
import swaggerLogo from '../assets/logos/swagger.png'
import postmanLogo from '../assets/logos/postman.png'
import onseignalLogo from '../assets/logos/onesignal.png'
import sqlLogo from '../assets/logos/sql.png'
import reactLogo from '../assets/logos/react.png'
import htmlLogo from '../assets/logos/html.png'
import cssLogo from '../assets/logos/css.png'
import postgresqlLogo from '../assets/logos/postgresql.png'
import linuxLogo from '../assets/logos/Tux.svg.png'
import sqliteLogo from '../assets/logos/sqlite.png'
import graphqlLogo from '../assets/logos/graphql.png'
import restapiLogo from '../assets/logos/restapi.png'
import ScrollFadeComponent from '../components/ScrollFadeComponents';
import { Label } from '../components/Label';
import Container from '../components/Container';
import MenuButton from '../components/MenuButton';
import StoreButton from '../components/StoreButton';
import MainPhoto from '../components/MainPhoto';
import Skill from '../components/Skill';


interface HomeProps {

}

const Home: FC<HomeProps> = (props) => {
    const contactSectionRef = useRef<HTMLDivElement | null>(null);
    const techSkillsSectionRef = useRef<HTMLDivElement | null>(null);
    const softSkillsSectionRef = useRef<HTMLDivElement | null>(null);
    const aboutSectionRef = useRef<HTMLDivElement | null>(null);
    const technologiesSectionRef = useRef<HTMLDivElement | null>(null);
    const projectsSectionRef = useRef<HTMLDivElement | null>(null);
    const [screenX, setScreenX] = useState(2000)
    const sidePadding = useMemo((): number => {
        if (screenX < 500) return 2
        if (screenX < 1000) return 4
        if (screenX < 1400) return 10
        return 12
    }, [screenX])

    const getCurrentScreenSize = () => {
        setScreenX(window.innerWidth)
    }


    const scrollToContact = () => {
        if (contactSectionRef.current) {
            contactSectionRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }

    const scrollToAbout = () => {
        if (aboutSectionRef.current) {
            aboutSectionRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }

    const scrollToTechnologies = () => {
        if (technologiesSectionRef.current) {
            technologiesSectionRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }

    const scrollToProjects = () => {
        if (projectsSectionRef.current) {
            projectsSectionRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }

    const scrollToTechSkills = () => {
        if (techSkillsSectionRef.current) {
            techSkillsSectionRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }

    const scrollToSoftSkills = () => {
        if (softSkillsSectionRef.current) {
            softSkillsSectionRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }


    useEffect(() => {
        getCurrentScreenSize()
        window.addEventListener('resize', getCurrentScreenSize);
        return () => {
            window.removeEventListener('resize', getCurrentScreenSize)
        }
    }, [])


    return (
        <Container maxWidth={850}>
            <Stack
                spacing={3}
                padding={sidePadding}
                paddingTop={4}
                paddingBottom={50}
            >
                <MenuButton
                    items={[
                        {
                            name: 'O mnie',
                            onClick: scrollToAbout,
                        },
                        {
                            name: 'Technologie',
                            onClick: scrollToTechnologies,
                        },
                        {
                            name: 'Projekty',
                            onClick: scrollToProjects,
                        },
                        {
                            name: 'Umiejętności techniczne',
                            onClick: scrollToTechSkills,
                        },
                        {
                            name: 'Umiejętności miękkie',
                            onClick: scrollToSoftSkills,
                        },
                        {
                            name: 'Kontakt',
                            onClick: scrollToContact,
                        },
                    ]}
                />

                <Stack
                    alignItems='center'
                    padding={"24px 0"}
                    ref={aboutSectionRef}
                >
                    <Name>
                        Karol Wilczak
                    </Name>
                    <Role>
                        junior backend developer
                    </Role>
                </Stack>
                <Line />
                <Label>
                    Coś o mnie
                </Label>
                <MainPhoto />
                <Line />
                <div ref={technologiesSectionRef} />
                <Label>
                    Technologie
                </Label>

                <Label>
                    * * *
                </Label>
                <TechnologyContainer>
                    <TechnologyItem
                        name='Nestjs'
                        img={nestLogo}
                    />
                    <TechnologyItem
                        name='Nodejs'
                        img={nodeLogo}
                    />
                    <TechnologyItem
                        name='MongoDB'
                        img={mongodbLogo}
                    />
                    <TechnologyItem
                        name='JavaScript'
                        img={jsLogo}
                    />
                    <TechnologyItem
                        name='TypeScript'
                        img={tsLogo}
                    />
                    <TechnologyItem
                        name='Postman'
                        img={postmanLogo}
                    />
                    <TechnologyItem
                        name='Swagger'
                        img={swaggerLogo}
                    />
                    <TechnologyItem
                        name='Git'
                        img={gitLogo}
                    />
                    <TechnologyItem
                        name='GitLab'
                        img={gitlabLogo}
                    />
                    <TechnologyItem
                        name='Rest API'
                        img={restapiLogo}
                    />
                </TechnologyContainer>
                <Label>
                    * *
                </Label>
                <TechnologyContainer>
                    <TechnologyItem
                        name='GraphQL'
                        img={graphqlLogo}
                    />

                    <TechnologyItem
                        name='SQLite'
                        img={sqliteLogo}
                    />
                    <TechnologyItem
                        name='OneSignal'
                        img={onseignalLogo}
                    />
                    <TechnologyItem
                        name='Firebase'
                        img={firebaseLogo}
                    />
                    <TechnologyItem
                        name='HTML'
                        img={htmlLogo}
                    />
                    <TechnologyItem
                        name='CSS'
                        img={cssLogo}
                    />
                </TechnologyContainer>

                <Label>
                    *
                </Label>
                <TechnologyContainer>
                    <TechnologyItem
                        name='React'
                        img={reactLogo}
                    />
                    <TechnologyItem
                        name='Ionic'
                        img={ionicLogo}
                    />
                    <TechnologyItem
                        name='SQL'
                        img={sqlLogo}
                    />
                    <TechnologyItem
                        name='PostgreSQL'
                        img={postgresqlLogo}
                    />
                    <TechnologyItem
                        name='Linux'
                        img={linuxLogo}
                    />
                </TechnologyContainer>

                <Line />

                <div ref={projectsSectionRef} />

                <ScrollFadeComponent>
                    <ProjectName>
                        {appConfig.greenfieldName}
                    </ProjectName>
                </ScrollFadeComponent>

                <ScrollFadeComponent>
                    <Description>
                        Aplikacja mobilna, stworzona w celu ułatwienia zarządzania gospodarstwem rolniczym.
                        <br />
                        <br />
                        Projekt wymyśliłem i sworzyłem wraz z bratem. Spostrzegliśmy wiele problemów w naszym gospodarstwie rolnym do których potrzebowaliśmy konkretnego rozwiązania.
                        <br />
                        <br />
                        Z tego zrodził się pomysł na aplikację, brat zajął się frontendem a ja backendem. Po wdrożeniu funkcji terminów wycieleń, testowaliśmy ją na własnym gospodarstwie, a po jakimś czasie dodaliśmy kolejne moduły i udostępniliśmy ją użytkownikom.
                        <br />
                        <br />
                        Od tamtej pory systematycznie ją rozwijamy, dodając nowe funkcję i moduły, aby móc dotrzeć do szerszego grona odbiorców. Sugerując się opinią użytkowników dopasowujemy te już istniejące funkcje, pod ich wymagania.
                        <br />
                        <br />
                        Oprócz typowego stwórz, edytuj, usuń, pobierz, aplikacja wymagała ode mnie obsługi autoryzacji użytkowników, implementacji powiadomień push oraz mail które odświeżają się po wskrzeszeniu serwera, cash'owania danych, jak i  wywoływania wydarzeń w zaplanowanej przyszłości (np. tydzień przed terminem wycielenia się krowy).
                        <br />
                        Dostępna jest także możliwość zrobienia zdjęcia paszportu krowy przez użytkownika, a program na tej podstawie wypełnia formularz, bez konieczności wpisywania danych ręcznie.
                        <br />
                        <br />
                        Technologie użyte podczas produkcji: (Nestjs, MongoDB, OneSignal / Firebase, Redis, React, Postman, Tesseract)
                    </Description>
                </ScrollFadeComponent>

                <ScrollFadeComponent>
                    <StoreButton
                        iosUrl={'https://apps.apple.com/us/app/greenfield/id6461776009'}
                        andridUrl={'https://play.google.com/store/apps/details?id=com.greenfield.mobile'}
                    />
                </ScrollFadeComponent>

                <ProjectSwiper
                    id='greenfield'
                    images={[
                        greenfield01,
                        greenfield02,
                        greenfield03,
                        greenfield04,
                        greenfield05,
                        greenfield06,
                        greenfield07,
                        greenfield08,
                        greenfield09,
                    ]}
                />
                <Line />
                <ScrollFadeComponent>
                    <ProjectName>
                        Tasko
                    </ProjectName>
                </ScrollFadeComponent>
                <ScrollFadeComponent>
                    <Description>
                        Aplikacja webowa. Pozwala zarządzać różnego rodzaju projektami, pomaga zespołom organizować, śledzić i zarządzać ich pracą.
                        <br />
                        <br />
                        Projekt stworzyłem wraz z bratem głównie na potrzeby aplikacji Greenfield, z powodu wysokich cen podobnych rozwiązań na rynku.
                        <br />
                        <br />
                        Aplikacja pozwala: przypisywać pracowników do zadań, zachowywać ich chronologię pracy poprzez wyodrębnianie najważniejszych lub nadrzędnych zadań.
                        <br />
                        Zawiera także: czas pracy wykonanych zadań, powiadomienia, załączanie plików, bloków kodu, przypisywanie współpracowników do konkretnych zadań,
                        podział obszaru roboczego na mniejsze projekty, czat oparty na webSocketach, oraz dodawanie komentarzy do zadań, które powiadamiają współpracowników mailowo
                        <br />
                        <br />
                        W tym projekcie jestem odpowiedzialny za stworzenie backendu, postawienie serwera, skonfigurowanie bazy danych, oraz funkcję automatycznego deploy'u na produkcję.
                        <br />
                        <br />
                        Technologie użyte podczas produkcji: (NestJS, MongoDB, Git CI/CD, Postman, Mailer, PM2, nginx)
                    </Description>
                </ScrollFadeComponent>
                <ScrollFadeComponent>

                    <br /> Adres aplikacji:
                    <br />
                    <Link href="https://tasko.space/">
                        <a target="_blank" rel="noopener noreferrer">www.tasko.space.pl</a>
                    </Link>
                </ScrollFadeComponent>
                <ProjectSwiper
                    id='tasko'
                    images={[
                        tasko1,
                        tasko3,
                        tasko2,
                        tasko4,
                        tasko5
                    ]}
                    aspectRatio={19 / 9}
                    maxWidth={window.innerWidth}
                />
                <Line />
                <ScrollFadeComponent>
                    <ProjectName>
                        PTRACKER
                    </ProjectName>
                </ScrollFadeComponent>

                <ScrollFadeComponent>
                    <Description>
                        Aplikacja do zarządzania procesami w zakładzie pracy produkującym części do dronów.
                        <br />
                        <br />
                        Pracuję przy tym projekcie po godzinach dla firmy programistycznej HXS z Krakowa.
                        <br />
                        Jestem w nim odpowiedzialny za backend, zaprojektowanie architektury bazy danych, wdrożenie automatyczego deployu oraz stworzenie szczegółowej dokumentacji Swagger
                        <br />
                        <br />
                        Projekt jest tworzony w celu automatyzacji pracy zakładu produkcji.
                        <br />
                        Pomaga zarządzać pracownikami, aktywami firmy, zużywać materiały oraz narzędzia, zatwierdzać kontrolę komponentów, zgłaszać materiały do defoteki oraz tworzyć schematy procesów produkcyjnych, które potem są realizowane przez pracowników.
                        <br />
                        <br />
                        Technologie użyte podczas produkcji: (Nestjs, MongoDB, OneSignal, Swagger, Git CI/CD)
                    </Description>
                    <br />
                    <br /> Adres dokumentacji Swagger:
                    <br />
                    <Link href="http://ptracker.hxspace.pl:3004/api#/">
                        <a target="_blank" rel="noopener noreferrer">ptracker.hxspace.pl/api/#/</a>
                    </Link>
                </ScrollFadeComponent>

                <ProjectSwiper
                    id='ptracker'
                    images={[
                        ptracker01,
                        ptracker02,
                        ptracker06,
                        ptracker07,
                        ptracker03,
                        ptracker04,
                        ptracker05,
                    ]}
                />
                <Line />

                <Stack spacing={2}>
                    <Label ref={techSkillsSectionRef}>
                        Umiejętności techniczne
                    </Label>
                    <Skill>
                        Tworzenie end-pointów z nastawieniem na maksymalną efektywność i
                        oszczędność serwera
                    </Skill>
                    <Skill>
                        Obsługa płatności online
                    </Skill>
                    <Skill>
                        Projektowanie bazy danych przed rozpoczęciem pracy nad projektem
                    </Skill>
                    <Skill>
                        Obsługa systemu kontroli wersji Git wraz z serwisem GitLab
                    </Skill>
                    <Skill>
                        Generowanie czytelnej dokumentacji API przy użyciu Postmana lub
                        Swaggera
                    </Skill>
                    <Skill>
                        Wysyłanie powiadomień typu Push z użyciem OneSignal/Firebase oraz wysyłanie maili
                    </Skill>
                    <Skill>
                        Zarządzanie magazynowaniem i hostowaniem plików
                    </Skill>
                    <Skill>
                        Automatyczny deploy CI/CD
                    </Skill>
                </Stack>
                <Line />
                <Stack spacing={2}>
                    <Label ref={softSkillsSectionRef}>
                        Umiejętności miękkie
                    </Label>
                    <Skill>
                        Organizacja projektu ze szczegółowym podziałem pracy na zadania
                    </Skill>
                    <Skill>
                        Zachowanie struktury "clean-code"
                    </Skill>
                    <Skill>
                        Dokładne estymowanie czasu pracy
                    </Skill>
                    <Skill>
                        Konsultowanie z zespołem, szczegółów nt. rozwijanego projektu, w celu dopasowania się do wymogów klienta
                    </Skill>
                    <Skill>
                        Komunikowanie pomysłów i obiekcji dotyczących projektów
                    </Skill>
                </Stack>
                <Line />
                <Label ref={contactSectionRef}>
                    Języki
                </Label>
                <ScrollFadeComponent>
                    <Description>
                        Polski - ojczysty
                        <br />
                        <br />
                        Rosyjski - podstawowy
                        <br />
                        <br />
                        Angielski - średniozaawansowany, zrozumiałe czytanie dokumentacji
                    </Description>
                </ScrollFadeComponent>
                <Line />
                <Label ref={contactSectionRef}>
                    Kontakt
                </Label>
                <ScrollFadeComponent>
                    <Description>
                        tel: 519 619 378
                        <br />
                        <br />
                        email: karol.wilczak@vp.pl
                    </Description>
                </ScrollFadeComponent>
            </Stack>
        </Container>
    )
}

export default Home;